import React from "react"
import { logout } from "../utils/auth"
import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import ThankYou from "../components/thank-you"

const Callback = () => {
  logout()

  return (
    <LayoutPage>
      <SEO title={"Loading..."} />
      <ThankYou title={"Please wait"}>
        <p>Your browser will redirect you now...</p>
      </ThankYou>
    </LayoutPage>
  )
}

export default Callback
